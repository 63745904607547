import * as KIT from "@frontend/kitui";
import { Method, ResponseType, AxiosError } from "axios";
import { redirect } from "react-router-dom";
import { clearAuthToken } from "@frontend/kitui";

import { cleanAuthTicketData } from "@root/ticket";
import { clearUserData } from "@root/user";
import { clearQuizData } from "@root/quizes/store";

import type { AxiosResponse } from "axios";
import { selectSendingMethod } from "@/ui_kit/FloatingSupportChat/utils";

interface MakeRequest {
  method?: Method | undefined;
  url: string;
  body?: unknown;
  useToken?: boolean | undefined;
  contentType?: boolean | undefined;
  responseType?: ResponseType | undefined;
  signal?: AbortSignal | undefined;
  withCredentials?: boolean | undefined;
}

type ExtendedAxiosResponse = AxiosResponse & { message: string };

export const makeRequest = async <TRequest = unknown, TResponse = unknown>(
  data: MakeRequest,
): Promise<TResponse> => {
  try {
    const response = await KIT.makeRequest<unknown, TResponse>(data);

    return response;
  } catch (nativeError) {
    const error = nativeError as AxiosError;

    selectSendingMethod({
      messageField: `status: ${error.response?.status}. Message ${(error.response?.data as ExtendedAxiosResponse)?.message}`,
      isSnackbar: false,
      systemError: true
    });
    if (
      error.response?.status === 400 &&
      (error.response?.data as ExtendedAxiosResponse)?.message ===
        "refreshToken is empty"
    ) {
      cleanAuthTicketData();
      clearAuthToken();
      clearUserData();
      clearQuizData();
      redirect("/");
    }

    throw nativeError;
  }
};
